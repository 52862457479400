import React, { Suspense, lazy } from 'react';
import { BrowserRouter, Route, Switch, useLocation } from 'react-router-dom';
import Loader from 'react-loader-spinner';
import posthog from 'posthog-js';

//if (!window.location.href.includes('localhost')) {
posthog.init('1gnzB22Fqppou_Z_j5ndJcAfdjiQcUmrngulE6iCOkY', {
  api_host: 'https://app.posthog.com',
});
//}

const AppDetails = lazy(() =>
  import('./routes/developer_dashboard/AppDetails')
);
const LinkBank = lazy(() => import('routes/developer_dashboard/LinkBanks'));
const Business = lazy(() => import('./routes/business/Business'));
const Dashboard = lazy(() => import('./routes/dashboard/Dashboard'));
const DeveloperDashboard = lazy(() =>
  import('./routes/developer_dashboard/DeveloperDashboard')
);
const GetStarted = lazy(() => import('./routes/get_started/GetStarted'));
const Home = lazy(() => import('./routes/home/Home'));
const Invoice = lazy(() => import('./routes/invoice/Invoice'));
const Learn = lazy(() => import('./routes/learn/Learn'));
const Pricing = lazy(() => import('./routes/pricing/Pricing'));
const Login = lazy(() => import('./routes/auth/Login'));
const Logout = lazy(() => import('./routes/auth/Logout'));
const TwoFactor = lazy(() => import('./routes/auth/TwoFactor'));
const Payout = lazy(() => import('./routes/payout/Payout'));
const ManagePayout = lazy(() => import('./routes/payout/Manage'));
const PayoutLink = lazy(() => import('./routes/payout_link/PayoutLink'));
const PhoneVerification = lazy(() => import('./routes/auth/PhoneVerification'));
const Privacy = lazy(() => import('./routes/legal/Privacy'));
const Profile = lazy(() => import('./routes/profile/Profile'));
const Refill = lazy(() => import('./routes/refill/Refill'));
const Reset = lazy(() => import('./routes/auth/Reset'));
const ResetForm = lazy(() => import('./routes/auth/ResetForm'));
const Register = lazy(() => import('./routes/auth/Register'));
const ScrollToTop = lazy(() => import('./components/ScrollToTop'));
const Terms = lazy(() => import('./routes/legal/Terms'));
const Withdraw = lazy(() => import('./routes/withdraw/Withdraw'));
const Contractor = lazy(() => import('./routes/landing_pages/Contractor'));

function App() {
  const location = useLocation();

  const query = new URLSearchParams(location.search);
  const backgroundColor = query.get('bgColor');

  return (
    <BrowserRouter>
      <div>
        <Suspense
          fallback={
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                height: '100vh',
                width: '100vw',
                backgroundColor: backgroundColor
                  ? '#' + backgroundColor
                  : '#f2f0ea',
              }}
            >
              <Loader
                type="Circles"
                visible={true}
                color="rgb(245, 158, 11)"
                height={100}
                width={100}
              />
            </div>
          }
        >
          <ScrollToTop />
          <Switch>
            <Route path="/" component={Home} exact />
            <Route path="/business" component={Business} />
            <Route path="/dashboard" component={Dashboard} />
            <Route
              path="/developer/dashboard"
              component={DeveloperDashboard}
              exact
            />
            <Route
              path="/developer/dashboard/app/:appId/link-bank"
              component={LinkBank}
            />
            <Route
              path="/developer/dashboard/app/:appId"
              component={AppDetails}
            />

            <Route path="/get_started" component={GetStarted} />
            <Route path="/invoice/:invoiceId" component={Invoice} />
            <Route path="/learn" component={Learn} />
            <Route path="/pricing" component={Pricing} />
            <Route path="/login" component={Login} />
            <Route path="/logout" component={Logout} />
            <Route path="/2fa" component={TwoFactor} />
            <Route path="/payout/:token/manage" component={ManagePayout} />
            <Route path="/payout/:token" component={Payout} />
            <Route path="/payout_link/:token" component={PayoutLink} />
            <Route path="/profile" component={Profile} />
            <Route path="/refill/:token" component={Refill} />
            <Route path="/register" component={Register} />
            <Route path="/password_reset/reset" component={ResetForm} />
            <Route path="/password_reset" component={Reset} />
            <Route path="/privacy" component={Privacy} />
            <Route path="/terms" component={Terms} />
            <Route path="/verify" component={PhoneVerification} />
            <Route path="/withdraw" component={Withdraw} />
            <Route path="/lp/contractor" component={Contractor} />
          </Switch>
        </Suspense>
      </div>
    </BrowserRouter>
  );
}

export default App;
